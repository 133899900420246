import { useState } from 'react';
import SelectCtPolarity from '../../../Selects/SelectCtPolarity';
import SelectPhase from '../../../Selects/SelectPhase';
import { DistributionPanelType } from '@energybox/react-ui-library/dist/types';

export const StatefulSelectPhase = ({
  value,
  onSelect,
  panelType,
}: {
  value: number;
  onSelect: (value: number) => void;
  panelType?: DistributionPanelType;
}) => {
  const [phase, setPhase] = useState(value);

  return (
    <SelectPhase
      noBottomLine
      value={phase}
      onSelect={(value: number) => {
        onSelect(value);
        setPhase(value);
      }}
      panelType={panelType}
      error={false}
    />
  );
};

export const StatefulSelectCtPolarity = ({
  value,
  onSelect,
}: {
  value: boolean;
  onSelect: (value: boolean) => void;
}) => {
  const [reversePolarity, setReversePolarity] = useState(value);

  return (
    <SelectCtPolarity
      noBottomLine
      value={reversePolarity}
      onSelect={(value: boolean) => {
        onSelect(value);
        setReversePolarity(value);
      }}
      error={false}
    />
  );
};
