import { Tooltip } from '@energybox/react-ui-library/dist/components';
import styles from './tooltip.module.css';

type Props = {
  isEquipmentPage?: boolean;
};

const RelayCircuitStatusTooltip: React.FC<Props> = ({ isEquipmentPage }) => {
  return (
    <Tooltip
      arrowDirection={isEquipmentPage ? 'closeBottom' : 'topRight'}
      extraClassNames={[styles.mainText]}
      content={
        <div className={styles.tooltipText}>
          <div>
            <b>Circuit Status</b>
          </div>

          <div className={styles.paddingTop}>
            Resulting state of the controlled circuit, based on Relay Wiring
            (Type) and Relay State (Output Status)
          </div>
        </div>
      }
    >
      Circuit Status
    </Tooltip>
  );
};

export default RelayCircuitStatusTooltip;
