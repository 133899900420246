import {
  Select,
  SelectItem,
} from '@energybox/react-ui-library/dist/components';
import React from 'react';

const ctPolarityValues = [false, true];

export enum ctPolarityLabel {
  false = 'Normal',
  true = 'Reverse',
}

type Props = {
  value: boolean;
  onSelect: (value: boolean) => void;
  noBottomLine?: boolean;
  error?: boolean;
  customErrorText?: string;
};

const SelectCtPolarity: React.FC<Props> = ({
  value,
  onSelect,
  error,
  customErrorText,
  noBottomLine = false,
}) => {
  return (
    <Select
      title={ctPolarityLabel[value.toString()]}
      error={error}
      customErrorText={customErrorText}
      noBottomLine={noBottomLine}
    >
      {ctPolarityValues.map(v => (
        <SelectItem
          key={ctPolarityLabel[String(v)]}
          isSelected={v === value}
          onSelect={() => onSelect(v)}
        >
          {ctPolarityLabel[String(v)]}
        </SelectItem>
      ))}
    </Select>
  );
};

export default SelectCtPolarity;
