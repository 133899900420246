import { Tooltip } from '@energybox/react-ui-library/dist/components';
import styles from './tooltip.module.css';

type Props = {
  isEquipmentPage?: boolean;
};

const RelayOutputStatusTooltip: React.FC<Props> = ({ isEquipmentPage }) => {
  return (
    <Tooltip
      arrowDirection={isEquipmentPage ? 'closeBottom' : 'topLeft'}
      extraClassNames={[styles.mainText]}
      content={
        <div className={styles.tooltipText}>
          <div>
            <b>Output Status</b>
          </div>

          <div className={styles.paddingTop}>
            <b>Solid Circle</b> = Activated/Energized Relay
          </div>
          <div className={styles.paddingTop}>
            <b>Empty Circle</b> = Normal-State/De-energized Relay
          </div>
        </div>
      }
    >
      Output Status
    </Tooltip>
  );
};

export default RelayOutputStatusTooltip;
