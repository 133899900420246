import { Tooltip } from '@energybox/react-ui-library/dist/components';
import styles from './tooltip.module.css';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { classNames } from '@energybox/react-ui-library/dist/utils';

type Props = {
  isEquipmentPage?: boolean;
  isIsoIconNeeded?: boolean;
};

const RelayTypeTooltip: React.FC<Props> = ({
  isEquipmentPage,
  isIsoIconNeeded,
}) => {
  const arrowDirection = () => {
    if (isEquipmentPage) {
      return 'closeBottom';
    } else if (isIsoIconNeeded) {
      return 'right';
    } else {
      return 'topLeft';
    }
  };

  return (
    <Tooltip
      underline={isIsoIconNeeded ? false : true}
      arrowDirection={arrowDirection()}
      content={
        <div
          className={classNames(
            styles.tooltipText,
            isIsoIconNeeded ? styles.alignText : ''
          )}
        >
          <div>
            <b>Type</b>
          </div>

          <div className={styles.paddingTop}>
            <b>N.C.</b> = Relay is wired in Normally Closed position, i.e. will
            switch power OFF when activated & will supply power to the circuit
            when de-energized in “Local Override”
          </div>
          <div className={styles.paddingTop}>
            <b> N.O.</b> = Relay is wired in Normally Open position, i.e. will
            switch power ON when activated & will cut power to the circuit when
            de-energized in “Local Override”
          </div>
        </div>
      }
    >
      {isIsoIconNeeded ? <IoIosInformationCircleOutline size={20} /> : 'Type'}
    </Tooltip>
  );
};

export default RelayTypeTooltip;
