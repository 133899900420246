import {
  Loader,
  MenuDropdown,
  MenuDropdownItem,
} from '@energybox/react-ui-library/dist/components';
import CardList, {
  CardListHeader,
  CardListRowData,
  Cell,
} from '@energybox/react-ui-library/dist/components/CardList';
import {
  Actuator,
  ActuatorPortTypeText,
  GatewayStates,
} from '@energybox/react-ui-library/dist/types';
import { SubscribedControlBoardsById } from '@energybox/react-ui-library/dist/types/SubscribedControlBoard';
import React from 'react';
import { connect } from 'react-redux';
import {
  getActuatorsByEquipment,
  showDeleteActuatorModal,
  showEditActuatorModal,
} from '../../actions/control_boards';
import {
  subscribeToDeviceStatus,
  unsubscribeFromDeviceStatus,
} from '../../actions/streamApi';
import ActuatorControlMode from '../../components/ActuatorControlMode';
import { ApplicationState } from '../../reducers';
import { SubscribedControlBoardOutputStates } from '../../reducers/subscribedControlBoardOutputStates';
import {
  renderCircuitStatus,
  renderOutputStatus,
} from '../Gateways/GatewayDetailPages/ShowControlBoardPage/ShowControlBoardPage';
import styles from './ActuatorsTable.module.css';
import RelayCircuitStatusTooltip from '../Tooltips/RelayCircuitStatusTooltip';
import RelayTypeTooltip from '../Tooltips/RelayTypeTooltip';
import RelayOutputStatusTooltip from '../Tooltips/RelayOutputStatusTooltip';

interface OwnProps {
  equipmentId: number;
  secondaryAction?: any | React.Component;
  setActuatorIdToEdit: (actuatorId: number) => void;
}

interface Props extends OwnProps {
  getActuatorsByEquipment: typeof getActuatorsByEquipment;
  showDeleteActuatorModal: typeof showDeleteActuatorModal;
  showEditActuatorModal: typeof showEditActuatorModal;
  subscribeToDeviceStatus: typeof subscribeToDeviceStatus;
  unsubscribeFromDeviceStatus: typeof unsubscribeFromDeviceStatus;
  actuators: Actuator[] | undefined;
  subscribedControlBoardOutputStates: SubscribedControlBoardOutputStates;
  subscribedControlBoards: SubscribedControlBoardsById;
  isLoading: boolean;
  runTest?: boolean;
  daResultsExist?: boolean;
}

class ActuatorsTable extends React.PureComponent<Props> {
  componentDidMount() {
    const { getActuatorsByEquipment, equipmentId } = this.props;

    getActuatorsByEquipment(equipmentId);
  }

  componentDidUpdate(prevProps: Props) {
    const { actuators, subscribeToDeviceStatus } = this.props;

    if (
      prevProps.actuators === undefined &&
      actuators !== undefined &&
      actuators.length > 0
    ) {
      actuators.forEach(actuator => {
        const controlBoard = actuator.controlBoard;

        if (!!controlBoard) {
          subscribeToDeviceStatus(
            controlBoard.vendor,
            controlBoard.uuid,
            controlBoard.id
          );
        }
      });
    }
  }

  componentWillUnmount() {
    const { actuators, unsubscribeFromDeviceStatus } = this.props;
    if (actuators && actuators.length > 0) {
      actuators.forEach(actuator => {
        const controlBoard = actuator.controlBoard;

        if (!!controlBoard) {
          unsubscribeFromDeviceStatus(
            controlBoard.vendor,
            controlBoard.uuid,
            controlBoard.id
          );
        }
      });
    }
  }

  render() {
    const {
      actuators,
      subscribedControlBoardOutputStates,
      subscribedControlBoards,
      equipmentId,
      secondaryAction,
      showDeleteActuatorModal,
      showEditActuatorModal,
      isLoading,
      setActuatorIdToEdit,
      runTest,
      daResultsExist,
    } = this.props;

    if (!actuators || isLoading) {
      return (
        <div className={styles.loading}>
          <Loader size={16} />
        </div>
      );
    }

    const header: CardListHeader[] = [
      {
        width: '2',
        content: 'Port',
      },
      {
        width: '3',
        content: 'Relay Name',
      },
      {
        width: '3',
        content: 'Device',
      },
      {
        width: '3',
        content: 'Description',
      },
      {
        width: '2',
        customHeader: <RelayTypeTooltip isEquipmentPage={true} />,
      },
      {
        width: '2',
        customHeader: <RelayOutputStatusTooltip isEquipmentPage={true} />,
      },
      {
        width: '2',
        customHeader: <RelayCircuitStatusTooltip isEquipmentPage={true} />,
      },
      {
        width: '2',
        content: 'Control Mode',
      },
      {
        width: '1',
        content: '',
      },
    ];

    const headerForRunTestPage: CardListHeader[] = [
      {
        width: '2',
        content: 'Relay#',
      },
      {
        width: '3',
        content: 'Relay Name',
      },
      {
        width: '2',
        customHeader: <RelayTypeTooltip isEquipmentPage={true} />,
      },
      {
        width: '2',
        customHeader: <RelayOutputStatusTooltip isEquipmentPage={true} />,
      },
      {
        width: '2',
        customHeader: <RelayCircuitStatusTooltip isEquipmentPage={true} />,
      },
      {
        width: '2',
        content: 'Control Mode',
      },
      {
        width: '1',
        align: 'right',
        content: 'Actions',
      },
    ];

    const numberOfGridColumns = header.reduce(
      (total, { width }) =>
        total + (width !== undefined ? Number.parseInt(width) : 0),
      0
    );

    const numberOfColumnsForRunTest = headerForRunTestPage.reduce(
      (total, { width }) =>
        total + (width !== undefined ? Number.parseInt(width) : 0),
      0
    );

    const sordertedData = [...actuators].sort(
      (itemA, itemB) => itemA.port - itemB.port
    );

    const data: CardListRowData[] = sordertedData?.map(actuator => {
      const subscribedActuatorStates =
        subscribedControlBoardOutputStates[actuator.controlBoardId]?.state;
      const isLocalOverrideActive = subscribedControlBoards[
        actuator.controlBoardId
      ]?.status?.gatewayStates?.includes(GatewayStates.CONTROL_OVERRIDE);

      return {
        key: `${actuator.controlBoardId}-${actuator.port}`,
        content: (
          <>
            <Cell width="2">Relay {actuator.port}</Cell>
            <Cell width="3">{actuator.title}</Cell>
            {!runTest && (
              <>
                <Cell width="3">
                  <span className={styles.boldText}>
                    {actuator.controlBoard.uuid}
                  </span>
                  {actuator.controlBoard.title}
                </Cell>
                <Cell width="3">{actuator.description}</Cell>
              </>
            )}
            <Cell width="2">{ActuatorPortTypeText[actuator.portType]}</Cell>
            <Cell width="2" className={styles.outputStatus}>
              {renderOutputStatus(
                actuator.port,
                subscribedActuatorStates,
                isLocalOverrideActive
              )}
            </Cell>
            <Cell width="2" className={styles.circuitStatus}>
              {renderCircuitStatus(
                actuator.port,
                actuator.portType,
                subscribedActuatorStates,
                isLocalOverrideActive
              )}
            </Cell>
            <Cell width="2">
              <ActuatorControlMode
                equipmentId={actuator.equipmentId}
                isLocalOverrideActive={isLocalOverrideActive}
              />
            </Cell>
            <Cell width="1" cellAlign="right" verticallyCenterContent>
              <MenuDropdown
                disabled={daResultsExist && !runTest ? true : false}
              >
                {/* {runTest && (
                  <MenuDropdownItem onSelect={() => {}}>
                    {RunTestButtonText.RUN_RELAY_TEST}
                  </MenuDropdownItem>
                )} */}
                <MenuDropdownItem
                  onSelect={() => {
                    setActuatorIdToEdit(actuator.id);
                    showEditActuatorModal(actuator.id, equipmentId);
                  }}
                >
                  Edit Relay
                </MenuDropdownItem>

                <MenuDropdownItem
                  isRed
                  onSelect={() => {
                    setActuatorIdToEdit(actuator.id);
                    showDeleteActuatorModal();
                  }}
                >
                  Delete Relay
                </MenuDropdownItem>
              </MenuDropdown>
            </Cell>
          </>
        ),
      };
    });

    return (
      <>
        <div className={styles.controls}>
          {!runTest && <h3>{actuators.length} Relays</h3>}
          {secondaryAction ? secondaryAction : ''}
        </div>
        <CardList
          header={!runTest ? header : headerForRunTestPage}
          data={data}
          headerClassName={styles.cardListHeader}
          numberOfGridColumns={
            !runTest ? numberOfGridColumns : numberOfColumnsForRunTest
          }
        />
      </>
    );
  }
}

const mapStateToProps = (
  {
    controlBoards,
    subscribedControlBoardOutputStates,
    subscribedControlBoards,
  }: ApplicationState,
  { equipmentId }: OwnProps
) => ({
  actuators: controlBoards.actuatorsByEquipmentId[equipmentId],
  subscribedControlBoardOutputStates,
  subscribedControlBoards: subscribedControlBoards.byId,
  isLoading: controlBoards.actuatorsLoading,
});

const mapDispatchToProps = {
  getActuatorsByEquipment,
  showDeleteActuatorModal,
  showEditActuatorModal,
  subscribeToDeviceStatus,
  unsubscribeFromDeviceStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActuatorsTable);
